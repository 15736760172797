.table-top-header {
    position: sticky;
    top: 0;
    background-color: white;
    text-align: justify;
}

.table-top {
    text-align: initial;
    width: 100%
}

.table-top td {
    padding: 7px;
}

.table-top th {
    padding: 2px 2px 10px;
}

tr {
    border-radius: 4px;
}

tr:nth-child(even) {
    background-color: #e8e8e8;
}

.name-td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
