@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.site-layout .site-layout-background {
  background: #fff;
}

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

.flag{
  font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 
    'Segoe UI Emoji', 'Segoe UI Symbol';
}
.ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
  left: 5px;
  top: 6px;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #010c33!important;
}

.ant-typography.title-primary, .title-primary {
  color: #010c33 !important;
}

h1 { font-family: 'Poppins', sans-serif; font-weight: 800; font-style: italic; }
h2 { font-family: 'Poppins', sans-serif; font-weight: 800; font-style: italic; }
h3 { font-family: 'Poppins', sans-serif; font-weight: 800; font-style: italic; }
h4 { font-family: 'Poppins', sans-serif; font-weight: 800; font-style: italic; }
h5 { font-family: 'Poppins', sans-serif; font-weight: 800; font-style: italic; }

.ant-menu-item-selected {
  background-color: none !important;
  border-left: 5px solid;
}

button.ant-btn.btn-primary-darkBlue span {
  padding: 0px;
  font-size: 12px;
}

.btn-primary-darkBlue {
  background-color: #010c33;
  color: #00e5a6;
  margin: 10px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.btn-primary-darkBlue:hover {
  background-color: #010c33;
  color: #00e5a6;
  margin: 10px;
  border-radius: 10px;
}

i .ant-menu-submenu-arrow {
  background-color: red;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #010c33;
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;  
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected { 
  background: none;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.site-layout-background {
  background: #fff;
}

.menuLateral {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #010c33;
  overflow-x: hidden;
  padding: 2em 0em 0em 0em; 
}


.ant-menu-submenu-title {
  color: red;
}

.menuLateral a {
  padding: 0.5em;
  text-decoration: none;
  color: #969696;
  display: block;
}

.menuLateral a:hover {
  color: white;
}

.menuLateral::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.menuLateral::-webkit-scrollbar-track {
  background: #2f4756;        /* color of the tracking area */
}

.menuLateral::-webkit-scrollbar-thumb {
  background-color: #16daa0;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #2f4756;  /* creates padding around scroll thumb */
}

.App {
  text-align: center;
}


.LayoutHeaderLogo {

}

.contentMenuOptions {
  margin-top: 40%;
  padding: 10px;
  padding-right: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.textMarketPlaceCountry {
  position: relative;
  bottom: 5px;
  font-size: 12px;
  margin-left: 2px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.content-div {
  padding: 20px;
}

.public-layout {
  min-height: "100vh";
  background: #fff !important;
}

.login-layout {
  /* min-height: '100vh'; */
  background: #fff !important;
  position: absolute;
  width: -webkit-fill-available;
  top: 0;
  bottom: 0;
  margin: auto;
}
/* colors */
.primary {
  color: #5365e3 !important;
}
.green {
  color: #1aae9f !important;
}
.darkBlue {
  color: #010c33 !important;
}
.gray {
  color: gray !important;
}
.orange {
  color: #e8833a !important;
}
/* background-colors */
.background-primary {
  background-color: #5365e3;
}
.background-green {
  background-color: #1aae9f !important;
}
.background-gray {
  background-color: gray;
}
/* widths */
.input-width-40-percent {
  width: 40% !important;
}
.input-width-90-px {
  width: 90px;
}
.input-width-100-percent {
  width: 100% !important;
}
/* margins */
.margin-left-200 {
  margin-left: 200px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
/* fonts */
.font-size-20 {
  font-size: 20px;
}
.font-size-14 {
  font-size: 14px;
}
/* float */
.float-right {
  float: right;
}
/* Title primary */
.ant-typography.title-primary,
.title-primary {
  color: #5365e3;
}
/* Text color gray */
.text-color-gray {
  color: gray;
}
/* Antd hover and select color */
/* .ant-btn:hover,
.ant-btn:focus { 
   background: #fff;
  border-color: #5365e3 !important; 
/* } */
.ant-menu-item:hover,
.ant-menu-item-selected {
  color: #5365e3 !important;
  border-color: #5365e3!important;
}

/* Align text card */
.ant-card-body > h3,
.ant-card-body > p {
  text-align: initial;
}
/* Antd button type link */
.btn-primary {
  color: #5365e3 !important;
}
.btn-primary-margin {
  color: #5365e3 !important;
  margin: 0 8px;
}
.btn-link {
  color: #5365e3 !important;
  height: 0px !important;
  padding: 0px 0px 0px 5px;
  border: none !important;
}
.btn-link:hover,
.btn-link:focus {
  color: #5365e3 !important;
  border: none !important;
  font-weight: 600;
}
.btn-link:focus {
  color: #5365e3 !important;
  border: none !important;
  font-weight: 600;
}
.btn-link-empty {
  background-color: #f5f5f5 !important;
  color: #b8b8b8 !important;
  border: none !important;
}
.btn-link-filled {
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
}
.btn-link-filled-margin {
  margin: 0 8px;
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
}

.btn-primary.ms-1 {
  margin-left: 5px;
}

.ant-col-24.flex-margin-top {
  display: flex;
  align-content: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1rem;
}


.justifiContentAttachments {
  text-align: left;
  justify-content: left;
}

.ant-tabs-tab:hover {
  color: #000 !important;
}
.ant-tabs-tab:active {
  color: #000 !important;
}

.ant-tabs-ink-bar {
  background: #010c33!important;
}

.spanTitleAttachments {
  color: #00E9A0;
  font-weight: 500;
}

.spanTextAlertAttachments {
  color: red;
}

.btn-basic-green {
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
}

.ant-progress-bg {
  background-color: #010c33!important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: solid #010c33!important;
  z-index: 2;   
}

.btn-basic-white {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.btn-basic-white:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.btn-link-filled-padding {
  margin: 0 8px;
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
}
.btn-link-filled:hover,
.btn-link-filled:focus {
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
  font-weight: 600;
}
.btn-link-filled:focus {
  background-color: #010c33!important;
  color: #fff !important;
  border: none !important;
  font-weight: 600;
}
/* Empty circle icon */
.dot {
  height: 10px;
  width: 10px;
  border: 1px solid red;
  color: red;
  background-color: "none";
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.dot-green {
  display: inline-block;
  margin-right: 4px;
  color: #1aae9f !important;
}
.dot-red {
  display: inline-block;
  margin-right: 4px;
  color: #ff0000 !important;
}
.dot-gray {
  height: 10px;
  width: 10px;
  border: 1px solid gray;
  color: gray;
  background-color: "none";
  border-radius: 50%;
  display: block;
  float: right;
  margin-top: 14px;
}

/* Credit Card input */
.bmOFCG {
  display: inline-block;
  border: 1px solid #d9d9d9;
  width: 100%;
}
.cNiGdI {
  width: 100%;
}

/* sider */
.ant-layout-sider {
  background: none;
}
/* Modal Stock */
.listing-details {
  grid-row-gap: 0px;
  row-gap: 0px;
  padding: 5px 5px 5px 5px;
  border-bottom-width: 1px;
  border-bottom-color: #6f7d89;
  border-bottom-style: solid;
}

.ant-input:hover {
  border-color: #5365e3 !important;
}

.ant-input:focus {
  border-color: #5365e3 !important;
}

.generic-spinner {
  display: flex;
  justify-content: center;
}

.generic-spinner-padding {
  padding: 50px 50px 50px 50px;
}

.ant-spin-dot-item {
  background-color: #5365e3 !important;
}

.ant-select-selector:hover {
  border-color: #5365e3 !important;
}

.ant-select-selector:focus {
  border-color: #5365e3 !important;
}

/* spin  */
.spin-loading-outlined {
  font-size: 20px;
  color: #fff !important;
}

.spin-inside-button {
  margin: 0px 8px 0px 8px !important;
}

/* con return side bar */
.side-bar-icon-back {
  font-size: 17px;
  color: #4d5e6d !important;
  display: "initial";
  margin-right: 30px;
}

/* sidebar */
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-left-margin-top {
  text-align: left;
  margin-top: 20px;
}

/* page: my Account */
.padding-layout {
  padding: 0 24px 24px;
}
.padding-layout-content {
  padding: 24px;
  margin: 0;
}

/* page: Orders  */

/* .order-table .ant-table-content {
  min-width: 900px;
} */

.order-table .ant-table-cell {
  padding: 4px;
}

.order-table .ant-table-thead .ant-table-cell {
  color: grey;
  border-bottom: 2px solid #8b8b8b;
}
.order-table-shipping-row {
  background-color: #e9ecf1;
}

.order-table-shipping-row .ant-table-selection-column {
  display: none;
}

.order-table-order-row .indent-level-1 {
  display: none;
}

.order-table-order-row .ant-table-cell-with-append {
  padding-left: 0px;
}

.order-table-shipping-row .ant-table-selection-column {
  display: none;
}

.order-table-product-row {
  color: grey;
  background-color: rgb(238, 237, 237);
}

.order-table-product-row:hover {
  background-color: rgb(238, 237, 237);
}

.order-table-product-row .ant-table-selection-column {
  display: none;
}

.text-capitalize{
  text-transform: capitalize;
}

.order-form-table .ant-form-item {
  margin-bottom: 0;
}

body{
  font-family:'Kanit' !important;
}

.utils-documents .ant-row {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.welcome-bar .ant-row {
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
}

.welcome-bar button {
  margin-right: 30px;
}
/* 
a, path, button, .btn-primary, a.span,.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,span.anticon.anticon-tag.btn-primary.home-listing-title-icon,
.ant-pagination-item-active a,.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.home-support-form-button{
  color: rgb(0 207 146) !important;
  --antd-wave-shadow-color: rgb(0 207 146);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom:2px solid rgb(0 207 146) !important;
}

path,::selection{
  background-color:rgb(0 207 146);
  fill:rgb(0 207 146);
}

.ant-btn-background-ghost.ant-btn-primary,.ant-pagination-item-active,.ant-menu-item:hover, .ant-menu-item-selected{
  border-color:rgb(0 207 146) !important;
}

html{
  --antd-wave-shadow-color:rgb(0 207 146) !important;
} */

/***IMAGEN CORPORATIVA****/

ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light,.ant-layout-footer{
  background-color:#010c33 !important;
}

.logo img.logo{
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.ant-menu-horizontal>.ant-menu-item a ,.ant-layout-footer{
  color: #fff !important;
  font-size: 1.3em;
  font-weight: 300;
}

.ant-menu-horizontal>.ant-menu-submenu a ,.ant-layout-footer{
  color: #fff !important;
  font-size: 1.3em;
  font-weight: 300;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
  color: #010c33!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom: 2px solid #010c33!important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
  margin-top:0 !important;
}


.ant-alert-warning svg path {
  color: #faad14 !important;
}

.ant-menu-item-active {
  color: red !important;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: yellow;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #00e5a6;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon {
  color: #00e5a6;
}

.ant-menu-item:hover, .ant-menu-item-selected {
  border-color: #00e5a6 !important;
}

/*a, path, .btn-primary, a.span,,
.ant-pagination-item-active a,.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.home-support-form-button,.title-primary,.ant-menu-item:hover{
  color: #969696 !important;
  --antd-wave-shadow-color: rgb(0 207 146);
}*/

.dot-red path{
  color:red;
  fill:red;
}

.ant-layout{
  background-color:#fff !important;
}

.ant-btn-primary{
  background-color:#010c33!important;
  border-color:#00e5a6!important;
}

.ant-btn-primary path{
  fill:#00e5a6;
}

.ant-card,.ant-table table{
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
    border: 1px solid #f2f2f4;
}

.ant-btn-background-ghost.ant-btn-primary,.ant-pagination-item-active{
  border-color:#010c33!important;
}

.ant-btn:hover{
  border-color:#00e5a6 !important;
  color:#00e5a6 !important;
}

.home-support-form-button,.ant-typography.title-primary, .ant-menu-item-selected{
  color:#00e5a6 !important;
}

.ant-row.welcome-bar{
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
    border: 1px solid #cfcfcf;
    padding: 1.5rem;
  margin-top:-40px;
}

.ant-collapse-header {
  font-weight: 500;
  text-decoration: underline;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon path{
 fill:#00E9A0; 
}

.ant-modal.buttonInfo button:first-of-type{
  display:none;
}

.ant-modal.buttonInfo p{
  padding: 1rem;
    text-align: justify;
}

.ant-page-header-heading-left {
  display: flex !important;
  align-items: flex-start !important;
  margin: 0;
  overflow: auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.tip-password{
  font-size: .8rem;
    color: #00cfa0;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.dataStudio iframe{
  width: 100%;
  border: 0;
  height: 100vh;
}

.dataStudio{
  margin-top:0;
}

ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light{
  margin:-1px auto;
  padding-left: 12%;
}

.resume .resume-listing ul{
  list-style: none;
  padding-left: 0;
  width: 30%;
}

.resume .resume-listing ul li{
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
}

.resume .resume-listing ul li svg{
  margin-top: 4px;
  margin-right: 4px;
}

.resume .resume-listing ul .title-status{
  width:50%;
}

.phone-code{
  width:40%;
}

.home-status-container{
  border-left: 11px solid #16daa0;
}

.home-status-listing{
  border-left: 2px dotted #16daa0;
}

.home-status-container span{
  font-size: 1.1em;
  font-weight: 400;
}

.home-welcome-text{
  font-size: 1.3em;
}

.home-status-container .ant-spin-nested-loading {
  margin-top: 2%;
  padding-bottom: 5%;
}

.home-status-listing span.anticon.anticon-unordered-list{
  margin-right: 1%;
  margin-top: 1.5%;
}

.home>.ant-row:not(:first-child) {
  background-color: #ededed;
}

.ant-row.welcome-bar button{
  position:absolute;
  bottom:0;
  margin:0;
  right:15px;
  height:50px;
}
.ant-btn-background-ghost.ant-btn-primary.resources{
  border:1px solid rgb(0 0 0 / 13%) !important;
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  color: #16daa0 !important;
  background-color: #fff !important;
}

.utils-documents .ant-list-split .ant-list-item:last-child{
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
    border: 1px solid #cfcfcf;
}

.utils-documents .ant-list-item-meta-title > a{
  display: inline-block;width: 100%;
}

.floating-menu{
  position: fixed;
  z-index: 2;
  bottom: 0;
  text-align: center;
  width:100%;
  left:0;
}

.floating-menu ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

.floating-menu li{
  padding-right: 15px;
  padding-left: 15px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  font-size: 1.3em;
  font-weight: 300;
  min-width: 100px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-height: 6vh;
  padding-top: 1.5vh;
}

.floating-menu .anticon svg,.floating-menu .anticon path{
  fill: #00e9a0;
  color: #00e9a0;
}

.floating-menu li:hover{
  background-color: #00e5a6;
  color:#fff;
}

.floating-menu li:hover svg, .floating-menu li:hover path{
  fill:#fff; 
}
.barChart {
    height: 327px;
    display: flex;
    width: 100%;
}

.barChart-header-card {
    display: flex;
}

.g2-tooltip-title {
    text-align: start;
}

.g2-tooltip-marker {
    background: #3be5a6 !important
}

.card {
    background-color: white;
    /*margin: 10px;*/
    /*padding:30px;*/
    /*padding-top:20px;*/
    border-radius: 5px;
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;*/
}

.cardHeader {
    margin-bottom: 10px;
}

.cardBody {}

.table-top-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    text-align: justify;
}

.table-top {
    text-align: initial;
    width: 100%
}

.table-top td {
    padding: 7px;
}

.table-top th {
    padding: 2px 2px 10px;
}

tr {
    border-radius: 4px;
}

tr:nth-child(even) {
    background-color: #e8e8e8;
}

.name-td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pie-chart-title {
    text-align: initial;
    text-transform: uppercase;
}

.home-ultima-actualizacíon {

}

.home-ultima-actualizacíon-icon {
    background-color: #f3f3f3;
}

.home-ultima-actualizacíon-icon:hover {
    background-color: #f3f3f3;
}

.home-info {
    margin-top: 43px;
    text-align: left;
    background-color: white;
}

.home-info-text {
    margin-left: 10px;
}

.ant-card {
    box-shadow: none;
}
.login-container {
    height: 100vh;
}

.login-left {
    height: 100%;
    width: 100%;
    background-image: url(/static/media/login-background.df95ce39.png); /*Firefox 3.6*/ /*opera*/ /*Safari*/
    background-size: 100% 100%; /*estandar css3*/
    -moz-border-image: url(/static/media/login-background.df95ce39.png) 0; /*Firefox 3.5*/    
}

.login-hr-texts {
    width: 100%;
    color:  linear-gradient(red, yellow);
}

.CheckboxRememberPassword {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.login-content-inputs {
    margin-top: 23%;
    margin-bottom: 250px;
    padding-left: 31%;
    padding-right: 31%;
}

.login-content-texts {
    margin-top: 40%;
    margin-bottom: 250px;
    padding-left: 150px;
    padding-right: 150px;
}

.login-logo {
    height: auto;
    width: 100%;
    max-width: 200px;
}

.login-email-item {
    margin-bottom: 4px;
    padding-top: 0px;
}

.login-password-item {
    margin-bottom: 4px;
}

.login-error {
    margin-bottom: 4px;
}

.request-password-error {
    margin-bottom: 10px;
    margin-top: 10px;
}

.reset-password-error {
    margin-bottom: 10px;
    margin-top: 10px;
}

.reset-password-item {
    margin-bottom: 4px;
    padding-top: 10px;
}

.login-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-title {
    margin-top: 10px;
    font-family: 'Trebuchet MS';
    font-size: 14pt;
    margin-bottom: 20px;
}

.login-divider {
    margin-top: 5px;
    margin-bottom: 10px;
}

.login-button-container {
    margin-top: 15px;
}

.recoverPassword, .ant-btn-link {
    color: #969696 !important;
    border:  none;
}

.my-seller-account-card{
    margin-top: 16px;
    border-color: #1AAE9F;
}
.col-align-rigth-margin-top{
    text-align: right; 
    margin-top: 20px; 
}
.text-divider{
    color:#C3CFD9;
    font-size: 12px;
}
.check-status-my-account{
    height: 10px;
    width: 10px;
    background-color: 'none';
    display: inline-block;
    float: right;
    margin-top: 14px;
}
.side-bar-menu{
    height: 100%; 
    border-right: 0px; 
    background-color: #F0F2F5; 
}
.side-bar-icon-item{
    margin-right: 2px !important;
}
.tab-pane-marketplace{
    text-transform: capitalize;
}

.contentOptionsPhone {
    width: 140px !important;
}
.document-modal-content {
    padding: 10px;
    margin-bottom: 30px;
}

.document-card-title {
    justify-content: space-between;
    border-bottom: 1.5px solid #92929275; 
    margin-bottom: 10px;
}

.document-card-title-content {
    display: flex;
    flex-direction: row;
}

.document-card-title-icon {
    font-size: 30px; 
    color: #08c;
    margin-right: 10px;
}

.document-content-list {
    height: 150px;
    overflow: auto;
}

.document-add-button {
    color: #5365E3; 
    border-color: #5365E3;
}

.document-add-button:hover {
    color: #344097 !important; 
    border-color: #344097 !important;
}

.document-video-icon {
    font-size: 18px;
    color: #d80e0e;
    margin-right: 5px;
    align-self: center;
}

.document-document-icon {
    font-size: 18px;
    color: #d88314;
    margin-right: 5px;
    align-self: center;
}

.document-list-item {
    font-weight: 600;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-decoration: underline;
    max-width: 475px;
    align-self: center;
    color: #2f2ff7;
}

.document-list-item-competitor {
    color: #000;
}

.document-action-button {
    margin-right: 5px;
    color: #5365E3;
}

.document-pinned-row {
    color: #5365E3;
}

.document-row-content {
    display: flex;
    flex: 1 1;
    flex-wrap: nowrap;
}

.document-row-edition {
    display: flex;
    flex: 1 1;
}

.document-row-edition-property {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.document-row-edition-last-property {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin-left: 10px; 
    margin-right: 10px;
}

.document-row-edition-label {
    margin: 0;
}

.document-card-container {
    margin-top: 40px;
}

.document-competitor {
    background-color: rgba(219, 219, 219, 0.5);
    padding: 5px;
    border-radius: 15px;
}

.document-row-parent {
    display: flex;
}

.document-row-parent:hover {
    background-color: #5364e310;
}
.side-bar-menu{
    height: 100%;
    border-right: 0px;
    background-color:#F0F2F5
}
.dot-gray-margin-left{
    margin-left: 40px;
}
.ref-to-quiz{
    margin-left: 40px;
    margin-top: 240px;
}
.side-bar-margin-top{
    margin-top: 50px;
}
.divider-margin{
    margin: 15px 0px 15px 0px;
}
.form-padding-top {
    padding-top: 15px;
}
.card-radio-group{
    display: flex;
}
.card-radio-inline{
    display: inline-block;
    width: 300px;
    margin: 5px;
}
.card-content-div {
    display: flex;
    flex-direction: column
}
.item-inline{
    display: inline-block;
    margin-left: 5px;
}
.text-card-radio{
    display: initial;
}
.span-card-radio{
    color: #c8ccce;
}
/* ant design form */
.ant-form-item-label > label{
    float: left;
}
.input-form-margin-bottom{
    margin-bottom: 0 !important;
}
.p-max-length{
    width: '60%';
}
/* 
.ant-table-cell {
    padding: 0px !important;
} */

.ant-collapse-header {
    color: #20303c !important;
}

.measures-container {
    width: 100%;
}

.measures-product-title {
    width: 100%; 
    border-bottom: 1px solid black;
    margin-bottom: 20px;
}

.measures-input {
    width: 100px;
}

.measures-select {
    width: 75px !important; 
    margin-left: 10px;
}

.measures-convertion {
    margin-left: 20px;
    color: #DFE6ED;
}

.measures-volume {
    margin-bottom: 50px;
}

.measures-table {
    width: 100%;
}

.measures-collapse {
    width: 100%;
}

.measures-help-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.measures-referral-title {
    color: #3F8C7E;
}

.measures-fulfillment-title {
    color: #D3475D;
}

.measures-storage-title {
    color: #E8853E;
}

.measures-help-card {
    flex: 1 1;
    margin-right: 15px;
}

.measures-help-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.measures-help-details-item {
    display: flex;
    margin-bottom: 5px;
}

.measures-help-details-item-icon {
    font-size: 8pt;
    color: #5365E3;
    margin-top: 3px;
    margin-right: 5px;
}

.measures-help-details-item-text {
    font-size: 8pt;
}

.measures-help-details-info-item {
    flex: 1 1;
    align-self: center;
}

.measures-help-details-info-item-container {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #F8F8F9;
    border: 2px solid #DFE6ED;
    border-radius: 5px;
    padding: 10px;
}

.measures-help-details-info-item-icon {
    font-size: 18pt;
    margin-right: 10px;
}

.measures-help-details-info-item-text {
    font-size: 11px;
    color: grey;
    font-weight: 600
}

.measures-table-price {
    align-self: center;
}

.measures-table-title-container {
    display: flex;
    flex-direction: column;
}

.measures-table-referral-title {
    border: 1px solid #1AAE9F;
    background-color: #8DD7CF;
    color: #5DADA2;
    padding: 3px;
    text-align: center;
    font-size: 9pt;
}

.measures-table-title-column-container {
    display: flex;
    text-align: center;
}

.measures-table-flex {
    flex: 1 1;
}

.measures-table-value-border {
    border: 2px solid #DFE6ED;
}

.measures-table-values-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.measures-table-fulfillment-title {
    border: 1px solid #E8833A;
    background-color: #F3C19D;
    color: #D3455B;
    padding: 3px;
    text-align: center;
    font-size: 9pt;
}

.measures-table-storage-title {
    border: 1px solid #F7C325;
    background-color: #FBE192;
    color: #E8853C;
    padding: 3px;
    text-align: center;
    font-size: 9pt;
}

.measures-table-total-amazon-title {
    border: 1px solid #5466E3;
    background-color: #A9B2F1;
    color: #5D6EE5;
    padding: 3px;
    text-align: center;
    font-size: 9pt;
}

.measures-table-cif-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5466E3;
    background-color: #A9B2F1;
    padding: 3px;
    text-align: center;
    font-size: 9pt;
}

.measures-table-cif-title {
    color: #5D6EE5;
}

.measures-table-cif-title-detail {
    font-size: 7pt;
    color: white;
    margin-left: 5px;
}

.draft-container {
    display: flex;
    flex-direction: column;
}

.draft-menu {
    margin-bottom: 20px;
}

.draft-card-container {
    display: flex;
}

.draft-card {
    flex: 1 1;
    padding: 12px;
}

.draft-text-image {
    display: flex;
    align-items: stretch;
}

.draft-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    margin-left: 20px;
    width: 250px;
    height: 250px;
    background-color: #F5F5F5;
}

.draft-tiny-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
    margin-left: 20px;
    width: 10%;
    height: 50px;
}

.draft-text-left-image {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.draft-text-container {
    display: flex;
    flex: 1 1;
}

.draft-text-label {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    text-align: left;
    color: #c8ccce;
    font-weight: bold;
    align-self: center;
}

.draft-text-value {
    flex: 1 1;
    text-align: left;
    align-self: center;
}

.draft-measure-value {
    margin-right: 20px;
}

.draft-measures-container {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
}

.draft-rest-container {
    display: flex;
    flex-direction: column;
}

.draft-buttons-container {
    margin: 50px;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    justify-content: center;
}

.draft-buttons-title {
    color: #383838;
    font-size: 16pt;
    font-weight: bold;
}

.draft-buttons-title-description {
    color: #c8ccce;
    margin-bottom: 20px;
}

.draft-buttons {
    margin-bottom: 10px;
    width: 100%;
}

.draft-buttons-1 {
    color: white;
}

.draft-buttons-2 {
    background-color: green;
    color: white;
}

.draft-buttons-3 {
    color: green;
}

.draft-preview-container {
    display: flex;
    overflow: scroll;
}

.draft-preview-images-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.draft-preview-image {
    width: 50px;
    height: 50px;
    border: 1px solid #ADB1B8 #A2A6AC #8D9096;
    margin-bottom: 10px;
    cursor: pointer;
}

.draft-preview-image-selected {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
    border: 1px solid #e77600;
    border-radius: 1px;
}

.draft-preview-main-image-container {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.draft-preview-main-image {
    width: 400px;
    height: 400px;
}

.draft-preview-main-image-footer {
    text-align: center;
}

.draft-preview-text-container {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.draft-preview-text-title {
    font-weight: bold;
    font-size: 18pt;
}

.draft-preview-text-rate {
    font-size: 15;
    margin-right: 10px;
}

.draft-preview-text-divider {
    margin-bottom: 10px;
    margin-top: 5px;
}

.draft-preview-text-title-price-container {
    margin-bottom: 10px;
}

.draft-preview-text-title-price {
    float: left;
}

.draft-preview-text-separator {
    margin-left: 5px;
}

.draft-preview-text-title-price-number {
    color: #B82704;
    font-weight: bold;
    font-size: 14px
}

.draft-preview-text-title-price-shipping {
    font-weight: bold;
    font-size: 12px;
}

.draft-preview-text-title-price-shipping-discount {
    font-size: 12px;
}

.draft-preview-text-title-price-shipping-details {
    font-size: 12px;
}

.draft-preview-text-title-price-discount {
    margin-bottom: 10px;
}

.draft-preview-text-category-id-container {
    margin-bottom: 10px;
    font-size: 12px;
}

.draft-preview-text-category-id-label {
    color: #c8ccce;
}

.draft-preview-text-category-id-value {
    font-weight: bold;
}

.draft-preview-text-variants-container {
    display: flex;
    overflow-x: scroll;
    height: 90px;
}

.draft-preview-text-variant-card {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 10px;
    border: 1px solid #ADB1B8 #A2A6AC #8D9096;
    border-radius: 1px;
    min-width: 150px;
    width: 190px;
    height: 60px;
    max-width: 200px;
    margin-bottom: 10px;
    margin-right: 20px;
    cursor: pointer;
}

.draft-preview-text-variant-card-selected {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: left;
    padding: 10px;
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
    border: 1px solid #e77600;
    border-radius: 1px;
    min-width: 150px;
    width: 190px;
    max-width: 200px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.draft-preview-text-variant-card-title {
    font-size: 14px;
}

.draft-preview-text-variant-card-price {
    color: #B82704;
    font-size: 14px;
}

.draft-preview-text-about-title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
}

.draft-preview-text-about-bullet-container {
    display: flex;
    font-size: 14px;
}

.draft-preview-text-about-bullet-icon {
    margin-top: 9px;
    width: 30px;
    margin-right: 5px;
}

.accepted-proposal-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 2px solid #1AAE9F;
}

.accepted-proposal-icon {
    color: #1AAE9F;
    font-size: 20pt;
    align-self: center;
}

.accepted-proposal-text-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin-left: 20px;
    align-items: flex-start;
}

.accepted-proposal-text-accept {
    font-size: 16px;
    color: #1AAE9F;
    font-weight: 600;
}

.accepted-proposal-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.accepted-proposal-button-next-step {
    margin-right: 10px;
    background-color: #5365E3;
    color: white;
}

.accepted-proposal-button-remake {
    color: #5365E3;
}

.proposal-container {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #FBE192;
}

.proposal-text {
    align-self: center;
}

.proposal-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.proposal-accept-button {
    margin-right: 10px;
    background-color: #1AAE9F;
    color: white;
}

.proposal-remake-button {
    color: #5365E3;
}

.pending-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
}

.pending-icon {
    margin-right: 10px;
    font-size: 25pt;
    color: #5365E3;
}

.pending-text-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 450px;
}

.pending-text-title {
    font-size: 18pt;
    color: #5365E3;
}

.pending-text-description {
    text-align: left;
}

.prices-client-file-container {
    display: flex;
}

.prices-client-file-label-container {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 100px;
    width: 250px;
}

.prices-client-file-label-description {
    color: #c8ccce;
}

.prices-client-file-upload-container {
    align-self: center;
    width: 100%;
}

.prices-client-file-upload {
    width: 60%;
}

.price-input-number {
    width: 100%;
}

.prices-table-container {
    display: flex; 
    justify-content: center;
}

.price-table-buttons {
    margin-right: 5px;
    color: #5365E3;
}

.price-table-add-button {
    margin-left: 10px;
    height: 30px;
    font-size: 11pt;

}
.icon-order-size{
    font-size: 32px;
}
.content-padding{
    padding: 24px;
    margin-top: 20px;
}
.sales-row-marketplaces{
    background-color: #e9ecf1;
}

.stock-row-marketplaces{
    background-color: #e9ecf1;
    margin-left: "100px";
}

.sales-title { 
    color: #dadada;
}

.sales-search-row{
    justify-content: flex-end;
    display: 'flex';
    flex-direction: row;
}

.sales-font-size{
    font-size: 'larger'
}

.analytics-sales-card {
    width: auto !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.analytics-sales-card-content {
    float: left !important;
}

.analytics-sales-title {
    font-size: 20px !important;
    margin-right: 10px !important;
}

.analytics-sales-title-icon {
    margin-right: 5px !important;
}

.analytics-sales-title-description {
    color: gray !important;
}

.analytics-sales-date-picker{
    width: '50%';
    text-align: 'left';
}
